@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
body {
  margin: 0;
  padding: 5vh 5vw;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.submit {
  margin-top: 3vh;
  min-width: 300px;
  padding: 10px 30px;
  font-size: 2rem;
  color: black;
  text-decoration: none;
  border: 1px solid black;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
  transition: ease-in-out 0.25s;
}
.submit:hover {
  color: white;
  background-color: black;
}