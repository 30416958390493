.app {
  height: 90vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
}
.options{
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 60vh;
  overflow-y: scroll;
}

.home {
  margin-bottom: 20px;
  font-size: 2rem;
}

.write {
  border: 1px solid black;
  padding: 10px 30px;
  border: 1px solid black;
  border-radius: 20px;
  margin-top: 20px;
  font-size: 16px;
 width: calc(50vw - 60px);
}

.stylee {
  margin-bottom: 10px;
  margin-left: 10px;
  width: 50vw;
  border: none;
  outline: none;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: white;
  border-radius: 20px;
  padding: 10px 30px;
  border: 1px solid black;
  min-width: 300px;
}
.highlight {
  color: white;
  border: none;
  background-color: #00D1D2;
  transition: ease-in-out 0.25s;
}
