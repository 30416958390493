.app {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding: 0 10%;
}
.icon {
  position: absolute;
  top: 3vh;
  left: 3vw;
}
.text {
  margin-bottom: 40px;
  font-size: 2rem;
}

